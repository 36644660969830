import Layout from "@/components/layout/layout";
import GlobalStyle from "@/components/util/globalStyle";
import {
  cachedAt,
  getCurrentServerCache,
  getPbSystem,
  getSystemFileContent,
  isCacheExisting,
  setNewCache,
} from "@/services/cacheService/cacheService";
import {
  ceSettings,
  setCeSettings,
} from "@/services/ceSettings/ceSettingsService";
import {
  LocalesStatus,
  getLocalesStatus,
} from "@/services/cmsLocalizationService/cmsLocalizationService";
import { getCmsSettingsServerSide } from "@/services/cmsSettings/cmsSettingsServerService";
import {
  cmsSettings,
  setCmsSettings,
} from "@/services/cmsSettings/cmsSettingsService";
import {
  cmsTranslations,
  setCmsTranslations,
} from "@/services/cmsTranslation/cmsTranslationService";
import { checkCmsUserAuthenticationServerSide } from "@/services/cmsUserService/cmsUserServerService";

import { getCmsUserSettingsServerSide } from "@/services/cmsUserSettings/cmsUserSettingsServerService";
import { globalCss, setGlobalCss } from "@/services/cssService/cssService";
import { setCurrentLocale } from "@/services/currentLocaleService/currentLocaleService";
import {
  customBodyScripts,
  customHeadScripts,
  setCustomBodyScripts,
  setCustomHeadScripts,
} from "@/services/customScriptService/customScriptService";
import {
  globalConfig,
  setGlobalConfig,
} from "@/services/globalConfig/globalConfigService";
import { emptyReduxState } from "@/store/emptyReduxState";
import { useStore } from "@/store/store";
import { ContentElementStoreSetting } from "@/types/ceSettings/ceSettings";
import { GlobalConfig } from "@/types/globalConfig/globalConfig";
import { StrapiCmsUser } from "@/types/strapi";
import logger from "@/utils/logger";
import { muiTheme } from "@/utils/muiTheme";
import {
  getCmsAccessToken,
  getCmsTranslations,
  getExpireAndRefreshDateFromAccessToken,
} from "@/utils/serverUtil";
import { ThemeProvider } from "@mui/material/styles";
// import "bootstrap/dist/css/bootstrap.min.css";
import "@/styles/styles.scss";
import { appWithTranslation } from "next-i18next";
import type { AppProps } from "next/app";
import App, { AppContext } from "next/app";
import { Provider } from "react-redux";
import nextI18nConfig from "../../next-i18next.config";
import useRegisterServiceWorker from "../hooks/useRegisterServiceWorker";

global.log = logger;

/**
 * _app.tsx
 *
 * This file contains the custom App component for our Next.js application.
 * It includes the implementation of the static method getInitialProps,
 * which performs server-side data fetching to initialize global
 * configuration, styles, and content settings before rendering pages.
 */
const MyApp = ({
  Component,
  pageProps,
}: AppProps<{
  initialReduxState: any;
  globalCss: string;
  globalConfig: GlobalConfig;
  ceSettings: ContentElementStoreSetting[];
  cmsTranslations: any;
  navigations: any;
  dynamicLists: any;
  cmsSettings: any;
  requestLocale: string;
  page: any;
  headScripts: string;
  bodyScripts: string;
  cachedAt: string;
}>) => {
  const store = useStore(pageProps.initialReduxState);
  setCmsTranslations(pageProps.cmsTranslations);
  setGlobalCss(pageProps.globalCss);
  setGlobalConfig(pageProps.globalConfig);
  setCeSettings(pageProps.ceSettings);
  setCustomHeadScripts(pageProps.headScripts);
  setCustomBodyScripts(pageProps.bodyScripts);

  if (pageProps.requestLocale !== pageProps?.page?.locale) {
    setCurrentLocale(pageProps?.page?.locale);
  } else {
    setCurrentLocale(pageProps.requestLocale);
  }

  if (
    pageProps.initialReduxState &&
    pageProps.initialReduxState.cmsUser.isCmsUserAuthenticated
  ) {
    setCmsSettings(pageProps.cmsSettings);
  }
  useRegisterServiceWorker();

  return (
    <Provider store={store}>
      <ThemeProvider theme={muiTheme}>
        <Layout
          navigations={pageProps.navigations}
          headScripts={pageProps.headScripts}
          bodyScripts={pageProps.bodyScripts}
          cachedAt={pageProps.cachedAt}
        >
          <Component {...pageProps} />
        </Layout>
        <GlobalStyle globalCssString={pageProps.globalCss} />
      </ThemeProvider>
    </Provider>
  );
};

/**
 * Fetches initial properties (props) for the application.
 *
 * @param {object} appContext - The context object for the application,
 *    including `ctx` which provides additional context.
 * @returns {Promise<object>} The initial props for the application.
 */
MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);
  const requestLocale = appContext.router.locale;

  // getInitialProps can run on server and client side
  // if there is no request in ctx this function is running on the client side
  if (appContext.ctx.req) {
    // check if a cms user is authenticated
    const currentCmsUser = await checkCmsUserAuthenticationServerSide(
      appContext.ctx.req,
      appContext.ctx.res
    );

    // get the current cached settings or fetch the newest
    const currentCache = await getCurrentCacheOrGetNewCache(
      requestLocale!,
      currentCmsUser
    );
    let cachedAt = currentCache.cachedAt;
    let globalConfigCache = currentCache.globalConfigCache;
    let navigationsCache = currentCache.navigationsCache;
    let globalCssCache = currentCache.globalCssCache;
    let dynamicListsCache = currentCache.dynamicListsCache;
    let ceSettingsCache = currentCache.ceSettingsCache;
    let headScriptsCache = currentCache.customHeadScriptsCache;
    let bodyScriptsCache = currentCache.customBodyScriptsCache;

    // get all pages as a content manager and cms info
    const cmsUserData = await getCmsUserData(appContext, currentCmsUser);

    // set the initial redux state from the server side
    const initialReduxState = getInitialReduxState(
      dynamicListsCache[requestLocale!] ??
        dynamicListsCache[process.env.DEFAULT_LOCALE!],
      currentCmsUser,
      cmsUserData
    );

    // check for updated settings and update the cache asynchronous if needed
    if (process.env.NEXT_PUBLIC_CACHE_DISABLED !== "true" && !currentCmsUser) {
      getNewestCache(requestLocale!);
    }

    return {
      ...appProps,
      pageProps: {
        initialReduxState: initialReduxState,
        globalCss: globalCssCache,
        globalConfig: globalConfigCache,
        cmsTranslations: cmsUserData.cmsTranslations,
        navigations:
          navigationsCache[requestLocale!] ??
          navigationsCache[process.env.DEFAULT_LOCALE!],
        dynamicLists:
          dynamicListsCache[requestLocale!] ??
          dynamicListsCache[process.env.DEFAULT_LOCALE!],
        ceSettings: ceSettingsCache,
        cmsSettings: cmsUserData.cmsSettings,
        requestLocale: requestLocale,
        headScripts: headScriptsCache,
        bodyScripts: bodyScriptsCache,
        cachedAt: cachedAt,
      },
    };
  } else {
    // clientside initial props
    // the redux state has no need to be re initialized
    // navigation, dynamicLists (footer) are in the current redux state
    return {
      ...appProps,
      pageProps: {
        globalCss: globalCss,
        globalConfig: globalConfig,
        cmsTranslations: cmsTranslations,
        ceSettings: ceSettings,
        cmsSettings: cmsSettings,
        requestLocale: requestLocale,
        headScripts: customHeadScripts,
        bodyScripts: customBodyScripts,
        cachedAt: cachedAt,
      },
    };
  }
};

const getCurrentCacheOrGetNewCache = async (
  requestLocale: string,
  currentCmsUser: StrapiCmsUser | null
) => {
  if (
    process.env.NEXT_PUBLIC_CACHE_DISABLED !== "true" &&
    !currentCmsUser &&
    isCacheExisting()
  ) {
    return getCurrentServerCache();
  }

  // No existing cache or request of currentCmsUser.
  const newestCache = await getNewestCache(requestLocale!, true);
  return setNewCache(
    newestCache.cachedAt,
    newestCache.globalConfigResponse,
    newestCache.navigationsResponse,
    newestCache.globalCssResponse,
    newestCache.dynamicListsResponse,
    newestCache.ceSettingsResponse,
    newestCache.headScriptsResponse,
    newestCache.bodyScriptsResponse
  );
};

const getNewestCache = async (
  requestLocale: string,
  hasImmediateEffect: boolean = false
) => {
  const pbSystem = await getPbSystem();
  if (pbSystem) {
    const cache = {
      cachedAt: pbSystem.cachedAt,
      globalConfigResponse:
        getSystemFileContent(pbSystem, "config-global", "json") ?? {},
      navigationsResponse: getSystemFileContent(
        pbSystem,
        "navigations",
        "json",
        true
      ) ?? { [process.env.NEXT_PUBLIC_DEFAULT_LOCALE!]: [] },
      globalCssResponse:
        getSystemFileContent(pbSystem, "css-global.min", "string") ?? "",
      dynamicListsResponse: getSystemFileContent(
        pbSystem,
        "dynamiclists",
        "json",
        true
      ) ?? { [process.env.NEXT_PUBLIC_DEFAULT_LOCALE!]: [] },
      ceSettingsResponse:
        getSystemFileContent(
          pbSystem,
          "config-contentelement-settings",
          "json"
        ) ?? [],
      headScriptsResponse:
        getSystemFileContent(pbSystem, "head", "string") ?? "",
      bodyScriptsResponse:
        getSystemFileContent(pbSystem, "body", "string") ?? "",
    };

    if (
      process.env.NEXT_PUBLIC_CACHE_DISABLED !== "true" &&
      (!isCacheExisting() ||
        (isCacheExisting() &&
          new Date(getCurrentServerCache().cachedAt).getTime() <
            new Date(pbSystem.cachedAt).getTime()))
    ) {
      global.log.info(
        `${isCacheExisting() ? "Reg" : "G"}enerate Cache. New: ${
          pbSystem.cachedAt
        }. ${
          isCacheExisting()
            ? `Previous: ${getCurrentServerCache().cachedAt}`
            : ""
        } ${hasImmediateEffect ? "(immediate effect)" : "(following request)"}`
      );

      if (
        isCacheExisting() &&
        new Date(getCurrentServerCache().cachedAt).getTime() <
          new Date(pbSystem.cachedAt).getTime()
      ) {
        setNewCache(
          cache.cachedAt,
          cache.globalConfigResponse,
          cache.navigationsResponse,
          cache.globalCssResponse,
          cache.dynamicListsResponse,
          cache.ceSettingsResponse,
          cache.headScriptsResponse,
          cache.bodyScriptsResponse
        );
      }
    }
    return cache;
  }
  return {
    cachedAt: "",
    globalConfigResponse: {},
    navigationsResponse: [],
    globalCssResponse: "",
    dynamicListsResponse: [],
    ceSettingsResponse: [],
    headScriptsResponse: "",
    bodyScriptsResponse: "",
  };
};

const getCmsUserData = async (
  appContext: AppContext,
  currentCmsUser: StrapiCmsUser | null
) => {
  let cmsTranslations: any = {};
  let cmsSettings: any = null;
  let tokenExpDate: Date | null = null;
  let renewTokenDate: Date | null = null;
  let localesStatus: LocalesStatus = {
    localesInSync: true,
    strapiLocales: [],
    nextLocales: [],
  };
  let cmsUserSettings: any = null;

  if (currentCmsUser) {
    const { expireDate, refreshDate } = getExpireAndRefreshDateFromAccessToken(
      getCmsAccessToken(appContext.ctx.req!)
    );
    tokenExpDate = expireDate;
    renewTokenDate = refreshDate;

    const [
      cmsSettingsResponse,
      cmsUserSettingsResponse,
      cmsTranslationsResponse,
      localesStatusResponse,
    ] = await Promise.all([
      getCmsSettingsServerSide(appContext.ctx.req!),
      getCmsUserSettingsServerSide(appContext.ctx.req!),
      getCmsTranslations(),
      getLocalesStatus(getCmsAccessToken(appContext.ctx.req!)!),
    ]);
    cmsSettings = cmsSettingsResponse;
    cmsTranslations = cmsTranslationsResponse;
    localesStatus = localesStatusResponse;
    cmsUserSettings = cmsUserSettingsResponse;
  }

  return {
    cmsTranslations,
    cmsSettings,
    tokenExpDate,
    renewTokenDate,
    localesStatus,
    cmsUserSettings,
  };
};

const getInitialReduxState = (
  dynamicListsCache: any,
  currentCmsUser: StrapiCmsUser | null,
  cmsUserData: {
    cmsTranslations: any;
    cmsSettings: any;
    tokenExpDate: Date | null;
    renewTokenDate: Date | null;
    localesStatus: LocalesStatus;
    cmsUserSettings: any;
  }
) => {
  if (currentCmsUser) {
    return {
      ...emptyReduxState,
      cmsUser: {
        ...emptyReduxState.cmsUser,
        isCmsUserAuthenticated: true,
        user: currentCmsUser,
        tokenExpDate: cmsUserData.tokenExpDate,
        renewTokenDate: cmsUserData.renewTokenDate,
        cmsUserSettings: cmsUserData.cmsUserSettings,
      },
      cmsGeneral: {
        ...emptyReduxState.cmsGeneral,
        localesStatus: cmsUserData.localesStatus,
      },
      general: {
        ...emptyReduxState.general,
        dynamicLists: dynamicListsCache,
      },
    };
  }
  return {
    ...emptyReduxState,
    cmsUser: emptyReduxState.cmsUser,
    cmsGeneral: emptyReduxState.cmsGeneral,
    general: {
      ...emptyReduxState.general,
      dynamicLists: dynamicListsCache,
    },
  };
};

export default appWithTranslation(MyApp, nextI18nConfig);
