import { useEffect, useState } from "react";
import { getRelationOptions } from "@/services/cmsAttributesService/cmsAttributesService";
import { useRouter } from "next/router";
import PbAutoComplete from "@/components/input/pbAutoComplete/pbAutoComplete";
import TextField from "@mui/material/TextField";
import useIsMounted from "@/hooks/useIsMounted";
import { useAppSelector } from "@/store/store";
import LoadingText from "@/components/loadingText/loadingText";

interface CmsAttributeRelationProps {
  name: string;
  value: any | Array<any>;
  relation: "oneToMany" | "oneToOne";
  target: string;
  onChange: (name: string, value: any | Array<any> | null) => void;
  optionLabelPath?: string;
  isNonLocalizedDisabled?: boolean;
  idPrefix: string;
}

export default function CmsAttributeRelation(
  props: Readonly<CmsAttributeRelationProps>
) {
  const isMounted = useIsMounted();
  const router = useRouter();
  const editView = useAppSelector((state) => state.cmsGeneral.editView);
  const [relationOptions, setRelationOptions] = useState<Array<any> | null>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchRelationOptions = async () => {
      let reqLocale = process.env.NEXT_PUBLIC_DEFAULT_LOCALE!;
      if (
        editView && // create is always in the default locale
        !props.isNonLocalizedDisabled
      ) {
        reqLocale = router.locale!;
      }
      const options = await getRelationOptions(reqLocale, props.target);
      if (isMounted()) {
        setRelationOptions(options);
        setIsLoading(false);
      }
    };
    if (relationOptions === null) {
      setIsLoading(true);
      fetchRelationOptions();
    }
  }, [
    isMounted,
    relationOptions,
    router,
    editView,
    props.isNonLocalizedDisabled,
  ]);

  const getOptionLabel = (option: any): string => {
    if (!option) {
      return "";
    }
    if (!props.optionLabelPath) {
      return option["name"] ?? option["id"];
    }
    if (props.optionLabelPath.includes(".")) {
      const pathSeg = props.optionLabelPath.split(".");
      let label: any = option;
      for (const seg of pathSeg) {
        if (label && label[seg] !== undefined) {
          label = label[seg];
        } else {
          label = option["name"] ?? option["id"];
          break;
        }
      }
      return label;
    } else {
      if (option[props.optionLabelPath] === undefined) {
        return option["name"] ?? option["id"];
      }
      return option[props.optionLabelPath];
    }
  };

  const getValue = (value: any) => {
    if (!value) {
      return null;
    }
    if (Array.isArray(value)) {
      return value
        .map((relation) => {
          const selectedOption = relationOptions?.find(
            (relationOption) => relationOption.id === relation.id
          );
          if (selectedOption) {
            return selectedOption;
          }
          return null;
        })
        .filter((s) => s !== null);
    } else {
      const selectedOption = relationOptions?.find(
        (relationOption) => relationOption.id === value.id
      );
      return selectedOption ?? null;
    }
  };

  return (
    <>
      {relationOptions !== null ? (
        <PbAutoComplete
          disablePortal={false}
          withCmsStyle
          fullWidth
          multiple={props.relation === "oneToMany"}
          options={relationOptions}
          renderOption={(props1, option) => {
            return (
              <li {...props1} key={option.id}>
                {getOptionLabel(option)}
              </li>
            );
          }}
          getOptionLabel={(option) => getOptionLabel(option)}
          defaultValue={getValue(props.value) ?? null}
          onChange={(_, value) => {
            props.onChange(props.name, value);
          }}
          id={"-" + props.idPrefix + "-" + props.name + "-selection"}
          renderInput={(params) => <TextField {...params} margin="none" />}
          disabled={props.isNonLocalizedDisabled}
        />
      ) : (
        <>{isLoading && <LoadingText />}</>
      )}
    </>
  );
}
