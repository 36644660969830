import {
  ContentElementStoreSetting,
  StoreSetting,
} from "@/types/ceSettings/ceSettings";
import { PbPageType } from "@/types/strapi";
import { STORE_COLOR_CLASS_PREFIX } from "@/utils/constants";
import { getEnforcedStoreValueId } from "@/utils/util";

export const CFG_CONTENT_ELEMENT_SETTING_NAME = "cfgStrContentElementSetting";

export let ceSettings: ContentElementStoreSetting[] | null = null;

/**
 * Checks and sanitizes all CE ids of the given draft page result
 * @see sanitizeCeSettingsId
 * @returns the sanitized draft page result
 */
export const sanitizeDraftPageResult = (result: any) => {
  const pageType: PbPageType = result.draftPage.type;
  const sanitizedCEs = result.draftPage.content.map((ce: any) => {
    const ceSetting = ce[CFG_CONTENT_ELEMENT_SETTING_NAME];

    if (!ceSetting) {
      return ce;
    }

    const ceSettingId = ceSetting.values[0];
    const ceSettingStoreType = ceSetting.storeType;
    const sanitizedId = sanitizeCeSettingsId(
      ceSettingStoreType,
      pageType.id,
      ceSettingId
    );

    // Note: This causes issues if the cmsUser saves the page while there is a potential misconfiguration
    // in the ceSettings.
    // -> sanitizedId is then stored.

    const sanitizedCE = {
      ...ce,
      [CFG_CONTENT_ELEMENT_SETTING_NAME]: {
        ...ce[CFG_CONTENT_ELEMENT_SETTING_NAME],
        values: [sanitizedId],
      },
    };

    console.log(
      `sanitizeDraftPageResult: after: ${sanitizedCE[CFG_CONTENT_ELEMENT_SETTING_NAME]?.values[0]}`
    );
    return sanitizedCE;
  });
  const sanitizedResult = {
    ...result,
    draftPage: { ...result.draftPage, content: sanitizedCEs },
  };

  return sanitizedResult;
};

/**
 * Checks if the given CE id exists for this type of CE.
 * If the id does not exist the CE default id for this type is returned.
 * @param storeType e.g. "image with markers" or "button"
 * @param pageType
 * @param id
 * @returns id or fallback id
 */
export const sanitizeCeSettingsId = (
  storeType: string,
  pageTypeId: number | null,
  id?: number
) => {
  if (!ceSettings || !pageTypeId) {
    return id;
  }

  const filteredCeSettings = filteredCeSettingsByStoreAndPageType(
    storeType,
    pageTypeId,
    ceSettings
  );

  const isIdExisting = filteredCeSettings.some(
    (ceSetting) => ceSetting.id === id
  );

  if (isIdExisting) {
    return id;
  }

  const sortedCeSettings = filteredCeSettings.sort((a, b) => a.id - b.id);

  global.log.info(
    `cesstr.id (${id}) does not match any existing ceSetting (pageType.id: ${pageTypeId}).`
  );
  if (!sortedCeSettings[0]) {
    // Should never be the case.
    global.log.error(
      `There is no existing alternative cesstr (pageType.id: ${pageTypeId})!`
    );
    return undefined;
  }
  global.log.info(
    `Replaced cesstr with id ${id} with ${sortedCeSettings[0].id} (${sortedCeSettings[0].name}).`
  );

  return sortedCeSettings[0].id;
};

/**
 * returns all ce settings that match the given store type and page type
 */
export const filteredCeSettingsByStoreAndPageType = (
  storeType: string,
  pageTypeId: number | null,
  contentElementSettings: ContentElementStoreSetting[] | null
) => {
  if (!contentElementSettings) {
    contentElementSettings = ceSettings;
  }

  if (!contentElementSettings || !pageTypeId) {
    return [];
  }

  let filteredCeSettings = contentElementSettings.filter((ceSetting) => {
    const isStoreType = ceSetting.type === storeType;
    if (!isStoreType) {
      return false;
    }

    const isPageTypeAll =
      !ceSetting.setting.pageTypes || !ceSetting.setting.pageTypes.values;
    if (isPageTypeAll) {
      return true;
    }

    const isPageTypeNone = ceSetting.setting.pageTypes.length === 0;
    if (isPageTypeNone) {
      return false;
    }

    const isPageType =
      ceSetting.setting.pageTypes.values.indexOf(pageTypeId) !== -1;
    return isPageType;
  });

  // If there's a storeType but (most likely because of pageType Configuration) no match in filteredCeSettings:
  // Add the default CeSetting of the storeType. (Requirement)
  if (filteredCeSettings.length === 0) {
    filteredCeSettings = [
      ...contentElementSettings
        .filter((ceSetting) => ceSetting.type === storeType)
        .sort((a, b) => a.id - b.id),
    ];
  }

  return filteredCeSettings;
};

/**
 * Sets the ceSettings variable above.
 * @param newCeSettings
 */
export const setCeSettings = (newCeSettings: any) => {
  if (newCeSettings) {
    ceSettings = newCeSettings;
  } else {
    global.log.warn("fetched ceSettings is null or empty.");
  }
};

/**
 * getNestedCeSettingsData
 * Returns the data of the nested CE-Store-Setting. (for example: headline in text-with-image, carousel ...)
 * @param ceSettings
 * @param fallback
 * @returns
 */
export const getNestedCeSettingsData = (
  ceSettings?: StoreSetting,
  fallback: any = undefined
) => {
  if (!ceSettings) {
    return fallback;
  }
  const ceSettingsId = valueFromStoreSetting(ceSettings);
  return dataValueFromCeStoreSetting(ceSettings, ceSettingsId, fallback);
};

/**
 * determineStoreValueId
 * Returns the value from storeSetting and considers potential to be enforced storeValue-Ids.
 * @param storeSetting
 * @param ceSettings
 * @returns
 */
export const determineStoreValueId = (
  storeSetting?: StoreSetting,
  ceSettings?: StoreSetting
) => {
  let strId;
  if (ceSettings && storeSetting) {
    strId = getEnforcedStoreValueId(storeSetting, ceSettings);
  } else if (storeSetting) {
    strId = valueFromStoreSetting(storeSetting);
  }
  return strId;
};

/**
 * valueFromStoreSetting
 * Returns the number in the values-Array.
 * @param storeSetting Store setting or CE setting.
 * @param pageTypeId
 * @param defaultIsCEFallback If true storeSetting must be for a CE.
 * In case of a non-existant CE a fallback id is returned.
 * @returns
 */
export const valueFromStoreSetting = (
  storeSetting: StoreSetting,
  pageTypeId?: number,
  defaultIsCEFallback?: boolean
): number | undefined => {
  if (!storeSetting?.values || storeSetting.values.length === 0) {
    return undefined;
  }

  if (defaultIsCEFallback && pageTypeId) {
    return sanitizeCeSettingsId(
      storeSetting.storeType,
      pageTypeId,
      storeSetting.values[0]
    );
  }

  return storeSetting.values[0];
};

/**
 * dataValueFromCeStoreSetting
 * Returns the data of the ceStoreSetting parameter.
 * @param ceStoreSetting
 * @param contentValue
 * @param defaultValue
 * @returns
 */
export const dataValueFromCeStoreSetting = (
  ceStoreSetting: StoreSetting | undefined,
  contentValue: number | undefined,
  defaultValue: any
) => {
  if (!ceStoreSetting?.data || !contentValue) {
    return defaultValue;
  }
  return ceStoreSetting.data[contentValue] ?? defaultValue;
};

/**
 * dataValueFromStoreSetting
 * Returns the data object of the storeSetting parameter.
 * @param storeSetting
 * @param defaultValue
 * @returns
 */
export const dataValueFromStoreSetting = (
  storeSetting: StoreSetting | undefined,
  defaultValue: any
) => {
  if (!storeSetting) {
    return defaultValue;
  }
  const storeValue = valueFromStoreSetting(storeSetting);
  if (!storeValue || !storeSetting.data) {
    return defaultValue;
  }
  return storeSetting.data[storeValue] ?? defaultValue;
};

/**
 * ceSettingsById
 * returns the ceSettings that have the id specific by the parameter
 * @param contentElementSettingId
 * @returns
 */
export const ceSettingById = (
  contentElementSettingId: number | undefined
): ContentElementStoreSetting | undefined => {
  if (!ceSettings || !contentElementSettingId) {
    return undefined;
  }
  return ceSettings?.find((setting) => setting.id === contentElementSettingId);
};

export const firstCeSettingByStoreType = (storeType: string) => {
  if (!ceSettings || !ceSettings.length) {
    return undefined;
  }
  return ceSettings.find((setting) => setting.type === storeType);
};

/**
 * compareStoreSettingValues
 * compares store setting values and returns true if they are equal.
 * @param source
 * @param target
 * @returns
 */
export const compareStoreSettingValues = (
  source: number[],
  target: number[]
) => {
  for (const id of source) {
    if (!target.includes(id)) {
      return false;
    }
  }
  return true;
};

/**
 * checkPersistStoreSettingValues
 * @param sourceValues
 * @param jsonStoreSettings
 * @returns
 */
export const checkPersistStoreSettingValues = (
  sourceValues: number[],
  jsonStoreSettings: StoreSetting | undefined
) => {
  const checkedValues: number[] = jsonStoreSettings?.data
    ? Object.entries(jsonStoreSettings.data)
        .map(([key, value]): number => {
          if (key !== "-1" && !value) {
            return 0;
          }
          return parseInt(key);
        })
        .filter((item) => item !== 0)
    : [];
  return compareStoreSettingValues(sourceValues, checkedValues);
};

/**
 * storeSettingByField
 * @param contentElementSetting
 * @param settingsField
 * @returns
 */
export const storeSettingByField = (
  contentElementSetting: ContentElementStoreSetting | undefined | null,
  settingsField: string | undefined
): StoreSetting | undefined => {
  if (!contentElementSetting || !settingsField) {
    return undefined;
  }

  if (settingsField.includes(".")) {
    const firstPartIndex = settingsField.indexOf(".");
    const parentStoreSetting = storeSettingByField(
      contentElementSetting,
      settingsField.substring(0, firstPartIndex)
    );

    if (!parentStoreSetting) {
      return undefined;
    }

    const selectedContentElementSettingId =
      valueFromStoreSetting(parentStoreSetting);
    return storeSettingByField(
      ceSettingById(selectedContentElementSettingId),
      settingsField.substring(firstPartIndex + 1)
    );
  }

  return contentElementSetting.setting[settingsField];
};

/**
 * Pass storeSetting (and if you need a fallback like "var(--pb-black)" or "#000") and get a CSS-Color as return.
 * @param storeSetting
 * @param fallback (optional)
 * @returns
 */
export const storeColorCss = (
  storeSetting: StoreSetting | undefined,
  fallback: string | undefined = undefined
): string => {
  let color = fallback ?? "unset";
  if (storeSetting && valueFromStoreSetting(storeSetting)) {
    color = `var(--${STORE_COLOR_CLASS_PREFIX}${valueFromStoreSetting(
      storeSetting
    )})`;
  }
  return color;
};

export const storeLinkClassName = (storeSetting: StoreSetting | undefined) => {
  if (!storeSetting) {
    return undefined;
  }
  const linkId = valueFromStoreSetting(storeSetting);
  return `str-link-${linkId}`;
};
