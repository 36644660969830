import CmsImage from "@/components/cms/cmsImage/cmsImage";
import FilePlaceholderIcon from "@/components/util/filePlaceholderIcon";
import { mediaManagerModalSelectFileAction } from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { StrapiUploadFile } from "@/types/strapi";
import clsx from "clsx";

interface CmsMediaManagerFileProps {
  id?: string;
  file: StrapiUploadFile;
}

export default function CmsMediaManagerFile(props: CmsMediaManagerFileProps) {
  const dispatch = useAppDispatch();
  const { currentItem, focus } = useAppSelector(
    (state) => state.cmsGeneral.mediaManagerModal
  );
  const handleClickEvent = () => {
    dispatch(mediaManagerModalSelectFileAction(props.file));
  };

  const isSelectedFile = () => {
    return (
      currentItem &&
      !("pathId" in currentItem) &&
      currentItem.id === props.file.id
    );
  };

  return (
    <>
      <div
        aria-hidden="true"
        id={props.file.name.toLowerCase().replace(" ", "-") ?? props.id}
        className={clsx(
          "media-manager-item-file",
          isSelectedFile() && "selected",
          !focus && "grey"
        )}
        onClick={handleClickEvent}
      >
        <div className="media-manager-item-file-image">
          {props.file.mime.includes("image") ? (
            <CmsImage
              // Prevents .gifs from being displayed in unadjusted size
              image={{
                ...props.file,
                formats: undefined,
                url: props.file.formats?.thumbnail
                  ? props.file.formats.thumbnail.url
                  : props.file.url,
              }}
              format="thumbnail"
              objectFit="cover"
              height={138}
              width={138}
            />
          ) : (
            <FilePlaceholderIcon
              mimeType={props.file.mime}
              height={120}
              width={120}
            />
          )}
        </div>
        <div className="media-manager-item-file-name">
          <p className="cms-modal-text">{props.file.name}</p>
        </div>
      </div>
      <style jsx>{`
        .media-manager-item-file {
          cursor: pointer;
          display: flex;
          flex-flow: column;
          height: 100%;
          position: relative;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
            0 1px 2px rgba(0, 0, 0, 0.24);
          border: 3px solid transparent;
        }

        .media-manager-item-file.selected {
          border: 3px solid #105f8d;
          &.grey {
            border: 3px solid #dee2e6;
          }
        }

        .media-manager-item-file-image {
          flex: 1 1 auto;
        }

        .media-manager-item-file-name {
          flex: 0 1 37px;
          padding: 4px 3px 3px;
          border-top: 1px solid #efefef;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            font-size: 12px;
            font-weight: 600;
            word-break: break-all;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      `}</style>
    </>
  );
}
